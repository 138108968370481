











































































































































































































































































$color: white;

.el-table .row-level-1 {
  background: darken($color, 2%);
}

.el-table .row-level-2 {
  background: darken($color, 4%);
}

.el-table .row-level-3 {
  background: darken($color, 6%);
}

.el-table .row-level-4 {
  background: darken($color, 8%);
}

.el-table .row-level-5 {
  background: darken($color, 10%);
}
